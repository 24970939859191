<template lang="pug">
  component.app-icon(:is="icon")
</template>

<script>
  import * as icons from "@/assets/icons"

  export default {
    props: {
      name: String
    },

    computed: {
      icon() {
        return icons[this.name]
      }
    }
  }
</script>

<style lang="sass" scoped>
  .app-icon
    transition: all 0.15s ease-in-out
</style>
